import React, { useEffect, useContext, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card } from '@components/ui';
import {
  Col,
  Row,
  Descriptions,
  Skeleton,
  Divider,
  Tag,
  Tooltip,
  Button,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import useSwr from '@hooks/useSwr';
import { getDocumentTitle } from '@src/utils/changeDocumentTitle';
import { extractDateInISOFormat, formatCurrency } from '@src/utils/formatters';
import SessionContext from '@src/store/SessionContext/SessionContext';
import Inventory from '@src/pages/Lorawan/Inventory';
import Transfer from '@src/pages/Lorawan/Transfer';
import BackToListing from '@src/components/BackToListing/BackToListing';

const { Paragraph } = Typography;

const DetailedRequest = () => {
  const { t } = useTranslation(['requests', 'attributes']);
  const { t: tMainContainer } = useTranslation('main-container');

  const {
    customer: { customerLogged, customerLoggedName },
  } = useContext(SessionContext);
  const navigate = useNavigate();

  const { requestId } = useParams();

  const { data: requestDetails } = useSwr(
    `/service-proxy/lorawanVirtualAccounts/${requestId}`,
  );

  const isPayableOrReceivable = useMemo(() => {
    const customerLoggedId = customerLogged?.id;
    const requestCustomerId = requestDetails?.customer?.id;
    if (customerLoggedId && requestCustomerId) {
      if (customerLoggedId === requestCustomerId) {
        return 'pay';
      }
      return 'receive';
    }
  }, [customerLogged?.id, requestDetails?.customer?.id]);

  const contractDetails = useMemo(
    () => requestDetails?.virtualPlan,
    [requestDetails],
  );

  const customerDetails = useMemo(
    () => requestDetails?.customer,
    [requestDetails],
  );

  useEffect(() => {
    getDocumentTitle(customerLoggedName, tMainContainer, customerDetails?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location, customerDetails, customerLoggedName, tMainContainer]);

  const typeOfPenalty = useMemo(() => {
    const type = t(`attributes:typesOfPenalty.${requestDetails?.loyaltyType}`);
    return t(`attributes:${type}`);
  }, [t, requestDetails]);

  const requestItemsDescriptions = useMemo(
    () => [
      {
        label: t('attributes:description'),
        value: requestDetails?.name,
        showItem: requestDetails?.name,
      },
      {
        label: t('attributes:activationPrice'),
        value: formatCurrency(parseFloat(requestDetails?.activationPrice)),
        showItem: requestDetails?.activationPrice,
      },
      {
        label: t('attributes:monthlyFee'),
        value: formatCurrency(parseFloat(requestDetails?.monthlyFee)),
        showItem: requestDetails?.monthlyFee,
      },
      {
        label: t('attributes:typesOfPenalty.title'),
        value: typeOfPenalty,
        showItem: true,
      },
      {
        label: t('attributes:penaltyAmount'),
        value: formatCurrency(parseFloat(requestDetails?.penalty)),
        showItem: requestDetails?.penalty && requestDetails?.loyaltyType === 1,
      },
      {
        label: t('attributes:loyaltyTime'),
        value: requestDetails?.loyaltyTime,
        showItem: requestDetails?.loyaltyType === 2,
      },
      {
        label: t('attributes:activationDate'),
        value:
          requestDetails?.activationDate &&
          extractDateInISOFormat(requestDetails?.activationDate),
        showItem: requestDetails?.activationDate,
      },
      {
        label: t('attributes:numberOfTradeInInvoices'),
        value:
          requestDetails?.tradeInMonths ??
          t('modal.configStep.noTradeInInvoices'),
        showItem: requestDetails?.tradeInMonths,
      },
      {
        label: t('attributes:trackingNumbers'),
        value: (
          <Paragraph
            copyable={{
              tooltips: [t('copyable.copy'), t('copyable.copied')],
            }}
            style={{ margin: 0, color: 'rgba(0, 0, 0, 0.65)' }}
          >
            {requestDetails?.trackingNumbers?.join(';')}
          </Paragraph>
        ),
        showItem: requestDetails?.trackingNumbers?.length > 0,
      },
      {
        label: t('attributes:uplinkCount'),
        value: requestDetails?.uplinkCount,
        showItem: requestDetails?.uplinkCount,
      },
      {
        label: t('attributes:excessUplinkPrice'),
        value: formatCurrency(parseFloat(requestDetails?.excessUplinkPrice)),
        showItem: requestDetails?.excessUplinkPrice,
      },
      {
        label: t('attributes:downlinkCount'),
        value: requestDetails?.downlinkCount,
        showItem: requestDetails?.downlinkCount,
      },
      {
        label: t('attributes:excessDownlinkPrice'),
        value: formatCurrency(parseFloat(requestDetails?.excessDownlinkPrice)),
        showItem: requestDetails?.excessDownlinkPrice,
      },
    ],
    [t, requestDetails, typeOfPenalty],
  );

  const chargeDifPerOperatorFormatted = useMemo(
    () =>
      contractDetails?.operatorDiffChargeFlag === 'S'
        ? t('attributes:chargingType.difPerOp')
        : t('attributes:chargingType.fixed'),
    [contractDetails, t],
  );

  const contractTypeFormatted = useMemo(() => {
    switch (contractDetails?.sharingType) {
      case 1:
        return t('attributes:type.sharedWithout');
      case 2:
        return t('attributes:type.sharedTotal');
      default:
        return '';
    }
  }, [contractDetails, t]);

  const contractDescriptionLink = useMemo(
    () =>
      contractDetails?.id ? (
        <Tooltip title={t('view-contract')}>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() =>
              navigate(`/lorawan/contracts/${contractDetails?.id}`)
            }
          >
            <span style={{ position: 'relative', top: -5 }}>
              {contractDetails?.planDesc}
            </span>
          </Button>
        </Tooltip>
      ) : (
        contractDetails?.planDesc
      ),
    [contractDetails, navigate, t],
  );

  const contractItemsDescriptions = useMemo(
    () => [
      {
        label: t('attributes:description'),
        value: contractDescriptionLink,
        showItem: contractDetails?.planDesc,
      },
      {
        label: t('attributes:externalId'),
        value: contractDetails?.externalId,
        showItem: contractDetails?.externalId,
      },
      {
        label: t('attributes:type.title'),
        value: contractTypeFormatted,
        showItem: contractTypeFormatted,
      },
    ],
    [t, contractDescriptionLink, contractTypeFormatted, contractDetails],
  );

  const clientItemsDescriptions = useMemo(
    () => [
      {
        label: t('attributes:name'),
        value: customerDetails?.name,
      },
      {
        label: t('attributes:nickname'),
        value: customerDetails?.nickname,
      },
      {
        label: t('attributes:cpfCnpj'),
        value: customerDetails?.cpfCnpj,
      },
      {
        label: t('attributes:tradingName'),
        value: customerDetails?.tradingName,
      },
      {
        label: t('attributes:customerType.title'),
        value: t(`attributes:customerType.${customerDetails?.type}`),
      },
    ],
    [t, customerDetails],
  );

  const DividerModel = useCallback(
    ({ title }) => (
      <Divider
        style={{
          marginTop: 24,
          marginBottom: 32,
          fontSize: '0.85rem',
          opacity: 0.7,
          textTransform: 'uppercase',
        }}
        orientation="left"
      >
        {title}
      </Divider>
    ),
    [],
  );

  return (
    <Skeleton loading={!requestDetails} active>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col lg={24} sm={24} xs={24}>
          <Card title={<BackToListing title={t('requestDetails')} />}>
            <Descriptions
              column={{ lg: 3, md: 2, sm: 2, xs: 1 }}
              style={{ marginTop: 24 }}
            >
              {requestItemsDescriptions?.map(
                ({ label, value, showItem }) =>
                  showItem &&
                  value && (
                    <Descriptions.Item key={label} label={label}>
                      {value}
                    </Descriptions.Item>
                  ),
              )}
            </Descriptions>
            <DividerModel title={t('attributes:contract')} />
            <Descriptions column={{ lg: 3, md: 2, sm: 2, xs: 1 }}>
              {contractItemsDescriptions?.map(
                ({ label, value, showItem }) =>
                  showItem &&
                  value && (
                    <Descriptions.Item key={label} label={label}>
                      {value}
                    </Descriptions.Item>
                  ),
              )}
            </Descriptions>

            <DividerModel title={t('attributes:client')} />
            <Descriptions column={{ lg: 3, md: 2, sm: 2, xs: 1 }}>
              {clientItemsDescriptions?.map(
                ({ label, value }) =>
                  value && (
                    <Descriptions.Item key={label} label={label}>
                      {value}
                    </Descriptions.Item>
                  ),
              )}
            </Descriptions>
          </Card>
        </Col>
      </Row>

      {requestDetails?.id && isPayableOrReceivable !== 'pay' && (
        <div style={{ marginTop: 16 }}>
          <Transfer
            isInTheRequestDetails
            defaultFilters={{
              virtualAccountId: requestDetails?.id,
            }}
            columnsToRemove={['oldCustomerName', 'newCustomerName', 'request']}
          />
        </div>
      )}

      {requestDetails?.id && (
        <div style={{ marginTop: 16 }}>
          <Inventory
            isInTheRequestDetails
            defaultFilters={{
              showContracted:
                isPayableOrReceivable === 'pay' ? 'true' : 'false',
              virtualAccountId: requestDetails?.id,
            }}
          />
        </div>
      )}
    </Skeleton>
  );
};

export default DetailedRequest;
