import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ClearOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Row, Tag, Tooltip } from 'antd';

import { useTranslation } from 'react-i18next';

const TagsManagement = ({ onChange, style, tags = [] }) => {
  const { t } = useTranslation('main');
  const inputRef = useRef(null);
  const [inputVisible, setInputVisible] = useState(false);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = useCallback(
    removedTag => {
      const newTags = tags.filter(tag => tag !== removedTag);
      onChange(newTags);
    },
    [onChange, tags],
  );

  const handleInputConfirm = useCallback(
    e => {
      const inputValue = e?.target?.value;
      if (inputValue && !tags.includes(inputValue)) {
        onChange([...tags, inputValue]);
      }
      setInputVisible(false);
    },
    [onChange, tags],
  );

  const renderTag = useCallback(
    tag => (
      <Tag
        key={tag}
        closable
        onClose={e => {
          e.preventDefault();
          handleClose(tag);
        }}
        style={{ marginBottom: 4 }}
      >
        {tag}
      </Tag>
    ),
    [handleClose],
  );

  const showInput = useCallback(() => setInputVisible(true), []);

  return (
    <>
      {tags?.map(renderTag)}
      <Row gutter={[8, 8]} style={style}>
        {inputVisible ? (
          <Input
            ref={inputRef}
            type="text"
            style={{ width: 80, marginTop: 8 }}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              icon={<PlusOutlined />}
              onClick={showInput}
              style={{
                borderStyle: 'dashed',
                opacity: 0.8,
                marginTop: tags?.length > 0 ? 8 : 0,
              }}
            >
              {t('newTag')}
            </Button>
            {tags?.length > 0 && (
              <Tooltip title="Limpar tags">
                <Button
                  type="link"
                  icon={<ClearOutlined />}
                  onClick={() => onChange([])}
                  style={{
                    marginTop: tags?.length > 0 ? 8 : 0,
                    marginLeft: 6,
                  }}
                />
              </Tooltip>
            )}
          </div>
        )}
      </Row>
    </>
  );
};

export default TagsManagement;
