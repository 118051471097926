import useTransfers from './useTransfers';
import useActivation from './useActivation';
import useChangeDescription from './useChangeDescription';
import useChangeTags from './useChangeTags';
import useDeactivation from './useDeactivation';
import useDownlinkBlockUnlock from './useDownlinkBlockUnlock';
import useUplinkBlockUnlock from './useUplinkBlockUnlock';

const LoraWan = {
  useTransfers,
  useActivation,
  useDeactivation,
  useUplinkBlockUnlock,
  useDownlinkBlockUnlock,
  useChangeDescription,
  useChangeTags,
};

export default LoraWan;
