import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Select,
  message,
  InputNumber,
  Input,
  Row,
  Col,
  Divider,
  Tag,
} from 'antd';
import { useTranslation } from 'react-i18next';
import ModalOfSteps from '@src/components/ModalOfSteps/ModalOfSteps';
import apiMiddleware from '@src/services/apiMiddleware';
import LabelModel from '@src/components/LabelModel/LabelModel';
import SelectCustomers from '@src/components/SelectCustomers/index';
import TagInput from '@src/components/TagInput/index';
import useSwr from '@src/hooks/useSwr';

const { Option } = Select;

const NewRequestModal = ({
  visible,
  onClose,
  newRequestMutate,
  defaultCustomerId,
  defaultContractId,
  showContracted,
}) => {
  const { t } = useTranslation([
    'requests',
    'attributes',
    'placeholders',
    'contracts',
  ]);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState();
  const [description, setDescription] = useState();
  const [loyaltyType, setLoyaltyType] = useState(0);
  const [loyaltyTime, setLoyaltyTime] = useState();
  const [virtualPlanId, setVirtualPlanId] = useState();
  const [penalty, setPenalty] = useState(0);
  const [uplinkCount, setUplinkCount] = useState(0);
  const [downlinkCount, setDownlinkCount] = useState(0);
  const [excessUplinkPrice, setExcessUplinkPrice] = useState(0);
  const [excessDownlinkPrice, setExcessDownlinkPrice] = useState(0);
  const [activationPrice, setActivationPrice] = useState();
  const [trackingNumbers, setTrackingNumbers] = useState();
  const [monthlyFee, setMonthlyFee] = useState(0);
  const [hasNoContracts, setHasNoContracts] = useState();
  const [contractsByClient, setContractsByClient] = useState();
  const [tradeInMonths, setTradeInMonths] = useState();

  const { data: contractData } = useSwr(
    selectedClient && defaultContractId
      ? `/service-proxy/broker/virtual-plan/${defaultContractId}?showContracted=${showContracted}`
      : null,
  );

  useEffect(() => {
    if (selectedClient && !defaultContractId) {
      apiMiddleware
        .get(`/service-proxy/lorawanVirtualPlans?customerId=${selectedClient}`)
        .then(res => {
          if (res?.status === 204) {
            setHasNoContracts(true);
          } else {
            setHasNoContracts(false);
            setContractsByClient(res?.data?.content);
          }
        });
    }
  }, [defaultContractId, selectedClient]);

  const contractsByClientOptions = useMemo(
    () =>
      contractsByClient !== '' &&
      contractsByClient?.map(({ id, planDesc }) => ({
        label: planDesc,
        value: id,
      })),
    [contractsByClient],
  );

  const contractDetails = useMemo(() => {
    if (selectedClient && defaultContractId && contractData) {
      return contractData.virtualContract;
    }
    if (contractsByClient !== '') {
      return contractsByClient?.find(contract => contract.id === virtualPlanId);
    }
  }, [
    contractsByClient,
    virtualPlanId,
    selectedClient,
    defaultContractId,
    contractData,
  ]);

  useEffect(() => {
    if (defaultContractId) {
      setVirtualPlanId(defaultContractId);
    }
    if (defaultCustomerId) {
      setSelectedClient(defaultCustomerId);
    }
  }, [defaultContractId, defaultCustomerId]);

  useEffect(() => {
    if (contractDetails) {
      setActivationPrice(contractDetails.activationPrice);
      setLoyaltyType(contractDetails.loyaltyType);
      setUplinkCount(contractDetails.uplinkCount);
      setDownlinkCount(contractDetails.downlinkCount);
      setExcessUplinkPrice(contractDetails.excessUplinkPrice);
      setExcessDownlinkPrice(contractDetails.excessDownlinkPrice);
      setMonthlyFee(contractDetails.monthlyFee);
      if (contractDetails.loyaltyType === 2) {
        setLoyaltyTime(contractDetails.loyaltyTime);
      }
      setPenalty(contractDetails.penalty);
    }
  }, [contractDetails]);

  const createAccount = useCallback(
    payload =>
      apiMiddleware.post('/service-proxy/lorawanVirtualAccounts', payload),
    [],
  );

  const cleanStateAndClose = useCallback(
    (cancelled = false) => {
      setActivationPrice(0);
      setDescription(undefined);
      setLoyaltyType(0);
      setLoyaltyTime(undefined);
      setVirtualPlanId(undefined);
      setPenalty(0);
      setSelectedClient(undefined);
      setUplinkCount(0);
      setDownlinkCount(0);
      setExcessUplinkPrice(0);
      setExcessDownlinkPrice(0);
      setActivationPrice(0);
      setTrackingNumbers(undefined);
      setMonthlyFee(0);
      setActivationPrice(0);
      setTradeInMonths(undefined);
      onClose(cancelled);
    },
    [onClose],
  );

  const handleCreateRequest = useCallback(async () => {
    setConfirmLoading(true);

    const payload = {
      name: description,
      activationPrice,
      virtualPlanId,
      loyaltyType,
      loyaltyTime,
      penalty,
      trackingNumbers,
      uplinkCount,
      downlinkCount,
      excessUplinkPrice,
      excessDownlinkPrice,
      monthlyFee,
      tradeInMonths,
    };

    try {
      await createAccount(payload);
      newRequestMutate(payload);
      message.success(t('modal.notifications.success'));
    } catch {
      message.error(t('modal.notifications.error'));
    }

    cleanStateAndClose(true);
    setConfirmLoading(false);
  }, [
    description,
    activationPrice,
    virtualPlanId,
    loyaltyType,
    loyaltyTime,
    penalty,
    trackingNumbers,
    uplinkCount,
    downlinkCount,
    excessUplinkPrice,
    excessDownlinkPrice,
    monthlyFee,
    tradeInMonths,
    cleanStateAndClose,
    createAccount,
    newRequestMutate,
    t,
  ]);

  const penaltyForCancellationOptions = useMemo(
    () => [
      {
        label: t('attributes:typesOfPenalty.noPenalty'),
        value: 0,
      },
      {
        label: t('attributes:typesOfPenalty.fixedPenalty'),
        value: 1,
      },
      {
        label: t('attributes:typesOfPenalty.penaltyOnContract'),
        value: 2,
      },
    ],
    [t],
  );

  const sharingType = useMemo(() => {
    const type = contractDetails?.sharingType;
    if (!type) {
      return;
    }
    const isPayPerUse =
      contractDetails?.payPerUseFlag === 'S' &&
      contractDetails?.operatorDiffChargeFlag === 'N';
    if (isPayPerUse) {
      return t('attributes:type.payPerUse');
    }
    const typeAttr = t(`attributes:type.${type}`);
    return t(`attributes:${typeAttr}`);
  }, [t, contractDetails]);

  const DescriptionModel = useCallback(
    ({ label, text }) => (
      <Tag
        style={{
          margin: 4,
          opacity: 0.6,
          padding: '2px 8px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span
          style={{ fontWeight: 'bold', fontSize: '0.8rem' }}
        >{`${label}: `}</span>
        <span style={{ fontSize: '1rem', marginLeft: 8 }}>{text}</span>
      </Tag>
    ),
    [],
  );

  const newRequestSteps = useMemo(
    () => [
      {
        title: t('attributes:client'),
        disableNextButton:
          !selectedClient || !contractsByClient || hasNoContracts,
        doNotShow: defaultCustomerId,
        loadingNextButton: selectedClient && !contractsByClient,
        content: (
          <Row gutter={[16, 16]} justify="center">
            <Col lg={16} xl={16} xs={24}>
              <SelectCustomers
                showLabel
                value={selectedClient}
                onChange={value => {
                  if (!value) {
                    setHasNoContracts(false);
                  }
                  setSelectedClient(value);
                }}
              />
              {hasNoContracts && (
                <div
                  style={{
                    marginTop: 10,
                    color: 'red',
                    fontSize: '0.88rem',
                    textAlign: 'left',
                  }}
                >
                  *&nbsp;
                  {t('clientWithoutContracts')}
                </div>
              )}
            </Col>
          </Row>
        ),
      },
      {
        title: t('attributes:contract'),
        disableNextButton: !virtualPlanId,
        loadingNextButton: virtualPlanId && !contractDetails,
        doNotShow: defaultContractId,
        content: (
          <Row gutter={[16, 16]} justify="center">
            <Col lg={16} xl={16} xs={24}>
              <LabelModel text={t('attributes:contract')} />
              <Select
                optionFilterProp={'label'}
                options={contractsByClientOptions}
                loading={!contractsByClientOptions}
                disabled={!contractsByClientOptions}
                placeholder={t('placeholders:contract')}
                style={{ width: '100%' }}
                value={virtualPlanId}
                allowClear
                showSearch
                onChange={setVirtualPlanId}
              />
            </Col>
          </Row>
        ),
      },
      {
        title: t('modal.stepsTitles.config'),
        disableNextButton: !description || description === '',
        content: (
          <>
            <Row gutter={[16, 10]} style={{ marginBottom: 10 }}>
              <Col span={24}>
                <LabelModel text={t('attributes:description')} isRequired />
                <Input
                  placeholder={t('placeholders:description')}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  allowClear
                />
              </Col>

              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:numberOfTradeInInvoices')} />
                <InputNumber
                  defaultValue={0}
                  min={0}
                  placeholder={t('placeholders:numberOfTradeInInvoices')}
                  step={1}
                  style={{ width: '100%' }}
                  value={tradeInMonths}
                  onChange={setTradeInMonths}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:trackingNumbers')} />
                <TagInput
                  newButtonText={t('attributes:newCode')}
                  onChange={setTrackingNumbers}
                />
              </Col>
            </Row>

            <Divider
              style={{
                marginTop: 40,
                fontSize: '0.85rem',
                opacity: 0.7,
                textTransform: 'uppercase',
              }}
            >
              {t('contracts:details.preDefined')}
            </Divider>

            <Row
              gutter={[16, 10]}
              style={{
                marginBottom: 24,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {sharingType ? (
                <DescriptionModel
                  label={t('attributes:type.title')}
                  text={sharingType}
                />
              ) : undefined}
            </Row>

            <Row
              gutter={[16, 10]}
              style={{
                marginBottom: 10,
              }}
            >
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:uplinkCount')} />
                <InputNumber
                  defaultValue={0}
                  min={0}
                  step={1}
                  style={{ width: '100%' }}
                  value={uplinkCount}
                  onChange={setUplinkCount}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:excessUplinkPrice')} />
                <InputNumber
                  defaultValue={0}
                  formatter={value => `R$ ${value}`}
                  min={0}
                  parser={value => value.replace('R$ ', '')}
                  step={0.01}
                  style={{ width: '100%' }}
                  value={excessUplinkPrice}
                  onChange={setExcessUplinkPrice}
                />
              </Col>
            </Row>
            <Row gutter={[16, 10]} style={{ marginBottom: 10 }}>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:downlinkCount')} />
                <InputNumber
                  defaultValue={0}
                  min={0}
                  step={1}
                  style={{ width: '100%' }}
                  value={downlinkCount}
                  onChange={setDownlinkCount}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:excessDownlinkPrice')} />
                <InputNumber
                  defaultValue={0}
                  formatter={value => `R$ ${value}`}
                  min={0}
                  parser={value => value.replace('R$ ', '')}
                  step={0.01}
                  style={{ width: '100%' }}
                  value={excessDownlinkPrice}
                  onChange={setExcessDownlinkPrice}
                />
              </Col>
            </Row>

            <Row gutter={[16, 10]} style={{ marginBottom: 10 }}>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:monthlyPayment')} />
                <InputNumber
                  defaultValue={0}
                  formatter={value => `R$ ${value}`}
                  min={0}
                  parser={value => value.replace('R$ ', '')}
                  step={0.01}
                  style={{ width: '100%' }}
                  value={monthlyFee}
                  onChange={setMonthlyFee}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:activationPrice')} />
                <InputNumber
                  defaultValue={activationPrice}
                  formatter={value => `R$ ${value}`}
                  min={0}
                  parser={value => value.replace('R$ ', '')}
                  step={0.01}
                  style={{ width: '100%' }}
                  value={activationPrice}
                  onChange={value => setActivationPrice(value)}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:penaltyForCancellation')} />
                <Select
                  options={penaltyForCancellationOptions}
                  style={{ width: '100%' }}
                  placeholder={t('placeholders:penaltyForCancellation')}
                  allowClear
                  showSearch
                  value={loyaltyType}
                  onChange={setLoyaltyType}
                />
              </Col>
              {loyaltyType === 1 && (
                <Col lg={12} xl={12} xs={24}>
                  <LabelModel text={t('attributes:penaltyAmount')} />
                  <InputNumber
                    defaultValue={0}
                    formatter={value => `R$ ${value}`}
                    min={0}
                    parser={value => value.replace('R$ ', '')}
                    step={0.01}
                    style={{ width: '100%' }}
                    onChange={setPenalty}
                    value={penalty}
                  />
                </Col>
              )}
              {loyaltyType === 2 && (
                <Col lg={12} xl={12} xs={24}>
                  <LabelModel
                    text={t('attributes:loyaltyTime')}
                    isRequired={true}
                  />
                  <Select
                    style={{ width: '100%' }}
                    value={loyaltyTime}
                    onChange={setLoyaltyTime}
                    allowClear
                  >
                    {[12, 24, 36].map(item => (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )}
            </Row>
          </>
        ),
      },
    ],
    [
      t,
      selectedClient,
      contractsByClient,
      hasNoContracts,
      defaultCustomerId,
      virtualPlanId,
      contractDetails,
      defaultContractId,
      contractsByClientOptions,
      description,
      tradeInMonths,
      sharingType,
      uplinkCount,
      excessUplinkPrice,
      downlinkCount,
      excessDownlinkPrice,
      monthlyFee,
      activationPrice,
      penaltyForCancellationOptions,
      loyaltyType,
      penalty,
      loyaltyTime,
    ],
  );

  return (
    <ModalOfSteps
      title={t('modal.title')}
      width="50%"
      handleConfirm={handleCreateRequest}
      visible={visible}
      steps={newRequestSteps}
      onClose={() => cleanStateAndClose(true)}
      confirmLoading={confirmLoading}
      disableConfirmButton={
        !description ||
        description === '' ||
        (loyaltyType === 2 && !loyaltyTime)
      }
    />
  );
};

export default NewRequestModal;
