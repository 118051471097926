import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { Button, Input, Tooltip, Select, Col, Tabs } from 'antd';
import { PlusCircleOutlined, FileSearchOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Label } from '@components/ui';
import NewRequestModal from './NewRequestModal/NewRequestModal';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import RoleChecker from '@components/RoleChecker/RoleChecker';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import useSearchParams from '@src/hooks/useSearchParams';
import { Filters, FiltersCard } from '@src/components/Filters/index';
import SessionContext from '@src/store/SessionContext/SessionContext';
import { extractDateInISOFormat, formatCurrency } from '@src/utils/formatters';
import SelectCustomers from '@src/components/SelectCustomers/index';
import './styles.less';
import rolesConstants from '@utils/rolesConstants';

const { Search } = Input;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30],
  total: 1,
};

const Requests = ({
  isInTheContractDetails,
  defaultCustomerId,
  defaultContractId,
  defaultFilters,
  defaultColumns,
}) => {
  const { keycloak } = useContext(KeycloakContext);
  const { hasCustomersRoles } = useContext(SessionContext);
  const customerType = keycloak?.idTokenParsed?.customerType;
  const isEndCustomer = customerType === 'CUSTOMER';
  const navigate = useNavigate();

  const searchLocation = useLocation().search;

  const { t } = useTranslation(['requests', 'attributes', 'contracts']);

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [modalVisible, setModalVisible] = useState(false);
  const [showContracted, setShowContracted] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [customerId, setCustomerId] = useState();
  const [description, setDescription] = useState();
  const [descriptionValue, setDescriptionValue] = useState();
  const [contractId, setContractId] = useState();

  const treatedShowContracted = useMemo(() => {
    if (isEndCustomer) {
      return 'true';
    }
    return showContracted !== 'archived' ? showContracted : undefined;
  }, [isEndCustomer, showContracted]);

  const { data, mutate } = useSwr('/service-proxy/lorawanVirtualAccounts', {
    page: pagination?.current - 1,
    linesPerPage: pagination?.pageSize,
    showContracted: treatedShowContracted,
    name: description,
    customerId,
    contractId,
    ...defaultFilters,
  });

  const { data: contractsByClient } = useSwr(
    customerId
      ? `/service-proxy/lorawanVirtualPlans?customerId=${customerId}`
      : null,
  );

  const contractsByClientOptions = useMemo(
    () =>
      contractsByClient?.content?.map(({ id, planDesc }) => ({
        label: planDesc,
        value: id,
      })),
    [contractsByClient],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'showContracted',
        setState: setShowContracted,
      },
      {
        name: 'customerId',
        setState: setCustomerId,
        inTheFilters: true,
      },
      {
        name: 'description',
        setState: setDescription,
        setStateValue: setDescriptionValue,
        inTheFilters: true,
      },
      {
        name: 'contractId',
        setState: setContractId,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleSetSearchParams, handleClearParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams(['showContracted', 'linesPerPage'], 'except');
      }
    },
    [showFilters, handleClearParams],
  );

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [showContracted, customerId, description, contractId]);

  useEffect(() => {
    if (!isEndCustomer) {
      const showContractedInParams = new URLSearchParams(searchLocation).get(
        'showContracted',
      );
      if (showContractedInParams === null) {
        handleSetSearchParams({ showContracted: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSetSearchParams, isEndCustomer]);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data]);

  const isErrorStatus = useCallback(status => status === 'ERROR', []);

  const renderLoyaltyType = useCallback(
    loyaltyType => {
      const types = {
        0: t('attributes:typesOfPenalty.noPenalty'),
        1: t('attributes:typesOfPenalty.fixedPenalty'),
        2: t('attributes:typesOfPenalty.penaltyOnContract'),
      };
      if (loyaltyType || loyaltyType === 0) {
        return types[loyaltyType];
      }
    },
    [t],
  );

  const allColumns = useMemo(
    () => [
      { title: t('columns.id'), dataIndex: 'id' },
      { title: t('columns.description'), dataIndex: 'name' },
      {
        title: t('columns.customerName'),
        dataIndex: 'customerName',
        render: (_, record) => record.customer?.name,
        visible: showContracted !== 'true',
      },
      {
        title: t('attributes:activationPrice'),
        dataIndex: 'activationPrice',
        align: 'center',
        render: activationPrice => formatCurrency(Number(activationPrice ?? 0)),
      },
      {
        title: t('attributes:typesOfPenalty.title'),
        dataIndex: 'loyaltyType',
        align: 'center',
        render: renderLoyaltyType,
      },
      {
        title: t('attributes:uplinkCount'),
        dataIndex: 'uplinkCount',
        align: 'center',
      },
      {
        title: t('attributes:downlinkCount'),
        dataIndex: 'downlinkCount',
        align: 'center',
      },
      {
        title: t('attributes:monthlyFee'),
        dataIndex: 'monthlyFee',
        align: 'center',
        render: monthlyFee => monthlyFee && formatCurrency(Number(monthlyFee)),
      },
      {
        title: t('attributes:activationDate'),
        dataIndex: 'activationDate',
        align: 'center',
        render: activationDate =>
          activationDate && extractDateInISOFormat(activationDate),
      },
      {
        title: t('attributes:contract'),
        dataIndex: 'virtualPlan',
        render: virtualPlan => (
          <Button
            type="link"
            onClick={() => navigate(`/lorawan/contracts/${virtualPlan?.id}`)}
          >
            {virtualPlan?.planDesc}
          </Button>
        ),
      },
      {
        title: t('columns.actions'),
        dataIndex: 'actions',
        align: 'center',
        render: (_, record) => (
          <Tooltip title={t('detailsButton')}>
            <Button
              icon={<FileSearchOutlined />}
              type={'link'}
              disabled={!record?.id}
              onClick={() => navigate(`/lorawan/requests/${record.id}`)}
            />
          </Tooltip>
        ),
      },
    ],
    [t, showContracted, renderLoyaltyType, navigate],
  );

  const columns = useMemo(() => {
    if (defaultColumns) {
      return allColumns
        ?.filter(col => col.visible !== false)
        .filter(column => defaultColumns.includes(column.dataIndex));
    }

    return allColumns.filter(col => col.visible !== false);
  }, [defaultColumns, allColumns]);

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  const handleNewRequestMutate = useCallback(
    newRequest => {
      mutate({
        ...data,
        content: [newRequest, ...data.content].slice(0, 10),
      });
      setPagination(oldPagination => ({
        ...oldPagination,
        totalElements: oldPagination.totalElements + 1,
      }));
    },
    [data, mutate],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: hasCustomersRoles && showContracted !== 'true',
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('columns.customerName'),
        item: (
          <SelectCustomers
            value={customerId && Number(customerId)}
            onChange={value => handleSetSearchParams({ customerId: value })}
          />
        ),
      },
      {
        visible: contractsByClientOptions,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('columns.planDesc'),
        item: (
          <Select
            optionFilterProp={'label'}
            options={contractsByClientOptions}
            loading={!contractsByClientOptions}
            disabled={!contractsByClientOptions}
            placeholder={t('columns.planDesc')}
            style={{ width: '100%' }}
            allowClear
            showSearch
            value={contractId && Number(contractId)}
            onChange={value => handleSetSearchParams({ contractId: value })}
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('columns.description'),
        item: (
          <Search
            placeholder={t('columns.description')}
            onSearch={value => handleSetSearchParams({ description: value })}
            onChange={({ target: { value } }) => setDescriptionValue(value)}
            value={descriptionValue}
            allowClear
          />
        ),
      },
    ],
    [
      t,
      handleSetSearchParams,
      hasCustomersRoles,
      customerId,
      descriptionValue,
      contractId,
      contractsByClientOptions,
      showContracted,
    ],
  );

  const rowClassName = useCallback(
    record =>
      isErrorStatus(record?.status) && 'request-creation-error-table-cell',
    [isErrorStatus],
  );

  const handleTabsChange = useCallback(
    key => {
      handleSetSearchParams({ showContracted: key });
      setPagination(oldPagination => ({
        ...oldPagination,
        current: 1,
      }));
    },
    [handleSetSearchParams],
  );

  return (
    <>
      {modalVisible && (
        <RoleChecker role={rolesConstants.LORAWAN_MANAGE_REQUESTS}>
          <NewRequestModal
            visible={modalVisible}
            newRequestMutate={handleNewRequestMutate}
            onClose={() => setModalVisible(false)}
            defaultContractId={defaultContractId}
            defaultCustomerId={defaultCustomerId}
            showContracted={showContracted}
          />
        </RoleChecker>
      )}
      {customerType === 'BROKER' && !isInTheContractDetails && (
        <Tabs
          type="card"
          defaultActiveKey="receive"
          activeKey={showContracted}
          onChange={handleTabsChange}
          style={{ background: '#ffffff' }}
        >
          <Tabs.TabPane key="false" tab={t('visions-steps.receive')} />
          <Tabs.TabPane key="true" tab={t('visions-steps.pay')} />
          {/* <Tabs.TabPane key="archived" tab={t('visions-steps.archived')} /> */}
        </Tabs>
      )}
      <Table
        title={
          <Filters
            title={t('title')}
            showFilters={showFilters}
            setShowFilters={handleShowFilters}
          />
        }
        columns={columns}
        allColumns={allColumns}
        data={data?.content}
        loading={!data?.content}
        pagination={pagination}
        tableKey={'lorawanRequests'}
        onChange={handleTableChange}
        rowClassName={rowClassName}
        extraActions={
          !isEndCustomer && (
            <RoleChecker role={rolesConstants.LORAWAN_MANAGE_REQUESTS}>
              <Tooltip title={t('newRequest')}>
                <Button
                  icon={<PlusCircleOutlined />}
                  size="large"
                  type="link"
                  onClick={() => setModalVisible(true)}
                />
              </Tooltip>
            </RoleChecker>
          )
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {tableFilters?.map(
                ({ col: { lg, xl, xs }, label, item, visible }) =>
                  visible && (
                    <Col key={label} lg={lg} xl={xl} xs={xs}>
                      {label && (
                        <div>
                          <Label color={'#575962'} htmlFor={label}>
                            {label}
                          </Label>
                        </div>
                      )}
                      {item}
                    </Col>
                  ),
              )}
            </FiltersCard>
          )
        }
      />
    </>
  );
};

export default Requests;
