import React, { useCallback, useContext, useMemo } from 'react';
import { Button, Dropdown, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ControlOutlined } from '@ant-design/icons';
import SessionContext from '@src/store/SessionContext/SessionContext';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import rolesConstants from '@src/utils/rolesConstants';

const ActionsMenu = ({ button, onActionClick, className }) => {
  const { t } = useTranslation('device-actions');
  const { keycloak } = useContext(KeycloakContext);
  const { userRoles } = useContext(SessionContext);
  const rolesToAccess = userRoles?.portal;
  const customerTypeLogged = keycloak.idTokenParsed?.customerType;
  const isSuperBroker = customerTypeLogged === 'SUPERBROKER';

  const menuItems = useMemo(
    () => [
      {
        role: rolesConstants.LORAWAN_DEACTIVATION,
        notDisplay: !isSuperBroker,
      },
      {
        role: rolesConstants.LORAWAN_TRANSFERS,
      },
      {
        role: rolesConstants.LORAWAN_UPLINK_BLOCK_UNLOCK,
      },
      {
        role: rolesConstants.LORAWAN_DOWNLINK_BLOCK_UNLOCK,
      },
      {
        role: rolesConstants.LORAWAN_CHANGE_DESCRIPTION,
      },
      {
        role: rolesConstants.LORAWAN_CHANGE_TAGS,
      },
    ],
    [isSuperBroker],
  );

  const renderLabel = useCallback(
    (item, disabled) => {
      const renderText = (
        <p
          style={{
            padding: '2px 12px',
            marginBottom: 2,
          }}
        >
          {t(`labels.lorawan.${item.role}`)}
        </p>
      );
      return disabled ? (
        <Tooltip key={item.role} title={t('root-cannot-see')}>
          {renderText}
        </Tooltip>
      ) : (
        renderText
      );
    },
    [t],
  );

  const items = useMemo(
    () =>
      menuItems
        ?.filter(item => {
          const havePermission = rolesToAccess?.includes(item.role);
          return havePermission && !item.notDisplay;
        })
        ?.map(item => {
          const disabled = item?.rootCannotSee && isSuperBroker;
          return {
            disabled,
            key: item.role,
            label: renderLabel(item, disabled),
          };
        }),
    [menuItems, rolesToAccess, isSuperBroker, renderLabel],
  );

  return (
    <Dropdown
      overlayClassName="overlay-menu"
      placement="bottomRight"
      menu={{ items, onClick: ({ key }) => onActionClick(key) }}
      className={className}
      trigger={['click']}
    >
      {button ?? (
        <Tooltip title={t('actions')}>
          <Button type="link" icon={<ControlOutlined />} />
        </Tooltip>
      )}
    </Dropdown>
  );
};

export default ActionsMenu;
