import React, {
  useEffect,
  useMemo,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { formatCurrency } from '@utils/formatters';
import { Card } from '@components/ui';
import {
  Col,
  Row,
  Skeleton,
  Descriptions,
  Tag,
  Divider,
  Tooltip,
  Button,
} from 'antd';
import { DollarOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import OverflowRangesConfig from '@components/ui/OverflowRangesConfig/OverflowRangesConfig';
import DifPerOperatorConfig from '@components/ui/DifPerOperatorConfig/DifPerOperatorConfig';
import { getDocumentTitle } from '@src/utils/changeDocumentTitle';
import useSwr from '@src/hooks/useSwr';
import SessionContext from '@src/store/SessionContext/SessionContext';
import ConsumptionCard from '@src/components/ConsumptionCard/ConsumptionCard';
import RoleChecker from '@src/components/RoleChecker/RoleChecker';
import rolesConstants from '@src/utils/rolesConstants';
import AddCredit from '@src/components/AddCredit/AddCredit';
import Requests from '../../Requests/Requests';
import BackToListing from '@src/components/BackToListing/BackToListing';

const DetailedContract = () => {
  const { t } = useTranslation([
    'contracts',
    'requests',
    'credits',
    'attributes',
  ]);
  const { t: tMainContainer } = useTranslation('main-container');
  const { contractId } = useParams();

  const [showAddCredit, setShowAddCredit] = useState(false);
  const [showContracted, setShowContracted] = useState(false);

  const {
    customer: { customerLoggedName, customerLogged },
  } = useContext(SessionContext);

  const { data: contract } = useSwr(
    `/service-proxy/broker/virtual-plan/${contractId}?showContracted=${showContracted}`,
  );

  const contractDetails = useMemo(() => contract?.virtualContract, [contract]);

  useEffect(() => {
    if (customerLogged && contractDetails) {
      setShowContracted(customerLogged.id === contractDetails.customer?.id);
    }
  }, [contractDetails, customerLogged]);

  const contractView = useMemo(
    () => (contractDetails?.status === 'ARCHIVED' ? 'filed' : 'active'),
    [contractDetails],
  );

  // You can only add credit to a shared agreement
  const itsASharedContract = useMemo(
    () => contractDetails?.sharingType === 2,
    [contractDetails],
  );

  const customerDetails = useMemo(
    () => contractDetails?.customer,
    [contractDetails],
  );

  const consumption = useMemo(() => {
    const balance = contract?.saldoContratoAgrupador;
    const convertMBToB = value => value * 1024 * 1024;
    return {
      totalFranchise:
        balance?.nuFranquiaTotal && convertMBToB(balance?.nuFranquiaTotal),
      franchiseBalance:
        balance?.nusaldoFranquia && convertMBToB(balance?.nusaldoFranquia),
      franchiseConsumption:
        balance?.nuConsumoFranquia && convertMBToB(balance?.nuConsumoFranquia),
      surplusConsumption:
        balance?.nuConsumoExcedente &&
        convertMBToB(balance?.nuConsumoExcedente),
      totalConsumption:
        balance?.nuConsumoTotal && convertMBToB(balance?.nuConsumoTotal),
    };
  }, [contract]);

  useEffect(() => {
    getDocumentTitle(
      customerLoggedName,
      tMainContainer,
      contractDetails?.planDesc,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    window.location.pathname,
    contractDetails,
    customerLoggedName,
    tMainContainer,
  ]);

  const chargeDifPerOperatorFormatted = useMemo(
    () =>
      contractDetails?.operatorDiffChargeFlag === 'S'
        ? t('attributes:chargingType.difPerOp')
        : t('attributes:chargingType.fixed'),
    [contractDetails, t],
  );

  const contractTypeFormatted = useMemo(() => {
    if (contractDetails?.payPerUseFlag === 'S') {
      return t('attributes:type.payPerUse');
    }

    switch (contractDetails?.sharingType) {
      case 1:
        return t('attributes:type.sharedWithout');
      case 2:
        return t('attributes:type.sharedTotal');
      case 3:
        return t('attributes:type.sharedMixed');
      default:
        return '';
    }
  }, [contractDetails, t]);

  const overflowRanges = useMemo(
    () =>
      contractDetails?.defaultOverflowPricing?.map(item => ({
        idOperadora: item.operatorId,
        nuFaixaInicialBytes: item.chargingInitialBytes,
        nuFaixaFinalBytes: item.chargingFinalBytes,
        nuValorNegociadoMbyte: item.megabyteChargeValue,
        nuCobrancaMinimaBytes: item.chargingMinimalBytes,
        nuCobrancaIncrementalBytes: item.chargingIncrementalBytes,
      })),
    [contractDetails],
  );

  const pricingPlanOperator = useMemo(
    () =>
      contractDetails?.defaultOperatorPricing?.map(item => ({
        idOperadora: item.operatorId,
        nuValorLiquido: item.chargingValue,
      })),
    [contractDetails],
  );

  const typeOfPenalty = useMemo(() => {
    const type = t(`attributes:typesOfPenalty.${contractDetails?.loyaltyType}`);
    return t(`attributes:${type}`);
  }, [t, contractDetails]);

  const handleActivatedTag = useCallback(
    value => {
      const [color, text] = value
        ? ['green', 'activated']
        : ['red', 'disabled'];
      return (
        <Tag color={color}>
          {t(`attributes:automaticBlocking.${text}`).toUpperCase()}
        </Tag>
      );
    },
    [t],
  );

  const handleHasTag = useCallback(
    value => {
      const [color, text] = value ? ['green', 'yes'] : ['red', 'no'];
      return (
        <Tag color={color}>{t(`attributes:hasTag.${text}`).toUpperCase()}</Tag>
      );
    },
    [t],
  );

  const contractItemsDescriptions = useMemo(
    () => [
      {
        label: t('attributes:type.title'),
        value: contractTypeFormatted,
        showItem: contractTypeFormatted,
      },
      {
        label: t('attributes:franchise'),
        value: `${contractDetails?.franchise} MB`,
        showItem: contractDetails?.franchise,
      },
      {
        label: t('attributes:activationPrice'),
        value: formatCurrency(parseFloat(contractDetails?.activationPrice)),
        showItem: contractDetails?.activationPrice,
      },
      {
        label: t('attributes:automaticBlocking.title'),
        value: handleActivatedTag(contractDetails?.automaticBlocking),
        showItem: true,
      },
      {
        label: t('attributes:roaming'),
        value: handleActivatedTag(contractDetails?.roaming),
        showItem:
          contractDetails?.roaming || contractDetails?.roaming === false,
      },
      {
        label: t('attributes:chargingType.title'),
        value: chargeDifPerOperatorFormatted,
        showItem: contractDetails?.payPerUseFlag === 'N',
      },
      {
        label: t('attributes:price'),
        value: formatCurrency(parseFloat(contractDetails?.price)),
        showItem:
          contractDetails?.price &&
          contractDetails?.operatorDiffChargeFlag === 'N',
      },
      {
        label: t('attributes:sharedFranchise'),
        value: `${contractDetails?.sharedFranchise} MB`,
        showItem:
          contractDetails?.sharingType && contractDetails?.sharingType === 3,
      },
      {
        label: t('attributes:mbExcValue'),
        value: formatCurrency(parseFloat(contractDetails?.megabyteExcPrice)),
        showItem:
          contractDetails?.megabyteExcPrice &&
          contractDetails?.payPerUseFlag === 'N',
      },
      {
        label: t('attributes:typesOfPenalty.title'),
        value: typeOfPenalty,
        showItem: true,
      },
      {
        label: t('attributes:penaltyAmount'),
        value: formatCurrency(parseFloat(contractDetails?.penalty)),
        showItem:
          contractDetails?.penalty && contractDetails?.loyaltyType === 1,
      },
      {
        label: t('attributes:loyaltyTime'),
        value: contractDetails?.loyaltyTime,
        showItem: contractDetails?.loyaltyType === 2,
      },
      {
        label: t('attributes:isca'),
        value: handleHasTag(contractDetails?.isca),
        showItem: true,
      },
      {
        label: t('attributes:iscaDurationInDays'),
        value: contractDetails?.iscaDurationInDays,
        showItem: contractDetails?.isca,
      },
      {
        label: t('attributes:iscaMaxRenewal'),
        value: contractDetails?.iscaMaxRenewal,
        showItem: contractDetails?.isca,
      },
    ],
    [
      t,
      contractTypeFormatted,
      contractDetails,
      handleActivatedTag,
      handleHasTag,
      chargeDifPerOperatorFormatted,
      typeOfPenalty,
    ],
  );

  const clientItemsDescriptions = useMemo(
    () => [
      {
        label: t('attributes:name'),
        value: customerDetails?.name,
      },
      {
        label: t('attributes:nickname'),
        value: customerDetails?.nickname,
      },
      {
        label: t('attributes:tradingName'),
        value: customerDetails?.tradingName,
      },
      {
        label: t('attributes:cpfCnpj'),
        value: customerDetails?.cpfCnpj,
      },
      {
        label: t('attributes:customerType.title'),
        value: t(`attributes:customerType.${customerDetails?.type}`),
      },
    ],
    [t, customerDetails],
  );

  const DividerModel = useCallback(
    ({ title }) => (
      <Divider
        style={{
          marginTop: 24,
          marginBottom: 32,
          fontSize: '0.85rem',
          opacity: 0.7,
          textTransform: 'uppercase',
        }}
        orientation="left"
      >
        {title}
      </Divider>
    ),
    [],
  );

  return (
    <Skeleton loading={!contract} active>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col lg={24} sm={24} xs={24}>
          <Card
            title={
              <BackToListing
                title={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {t('details.title')}
                    {itsASharedContract && (
                      <RoleChecker role={rolesConstants.ADD_CREDIT_TO_CONTRACT}>
                        <Tooltip title={t('credits:add-credit')}>
                          <Button
                            icon={
                              <DollarOutlined style={{ fontSize: '1.6rem' }} />
                            }
                            type="link"
                            onClick={() => setShowAddCredit(true)}
                          />
                        </Tooltip>
                      </RoleChecker>
                    )}
                  </div>
                }
              />
            }
          >
            <Descriptions
              column={{ lg: 3, md: 2, sm: 2, xs: 1 }}
              style={{ marginTop: 24 }}
            >
              <Descriptions.Item
                key={t('attributes:planDesc')}
                label={t('attributes:planDesc')}
              >
                {contractDetails?.planDesc}
              </Descriptions.Item>

              {contractDetails?.externalId && (
                <Descriptions.Item
                  key={t('attributes:externalId')}
                  label={t('attributes:externalId')}
                >
                  {contractDetails?.externalId}
                </Descriptions.Item>
              )}
              {contractDetails?.motOperators && (
                <Descriptions.Item
                  key={t('attributes:operator')}
                  label={t('attributes:operator')}
                >
                  {contractDetails?.motOperators?.name}
                </Descriptions.Item>
              )}
            </Descriptions>
            <DividerModel title={t('details.billingModel')} />
            <Descriptions column={{ lg: 3, md: 2, sm: 2, xs: 1 }}>
              {contractItemsDescriptions?.map(
                ({ label, value, showItem }) =>
                  showItem &&
                  value && (
                    <Descriptions.Item key={label} label={label}>
                      {value}
                    </Descriptions.Item>
                  ),
              )}
            </Descriptions>

            <DividerModel title={t('attributes:client')} />

            <Descriptions column={{ lg: 3, md: 2, sm: 2, xs: 1 }}>
              {clientItemsDescriptions?.map(
                ({ label, value }) =>
                  value && (
                    <Descriptions.Item key={label} label={label}>
                      {value}
                    </Descriptions.Item>
                  ),
              )}
            </Descriptions>
          </Card>
        </Col>
      </Row>

      {contractDetails &&
        !(
          contractDetails?.sharingType === 1 &&
          contractDetails?.payPerUseFlag === 'N'
        ) && (
          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col lg={24} sm={24} xs={24}>
              <ConsumptionCard data={consumption} />
            </Col>
          </Row>
        )}

      {contractDetails?.payPerUseFlag === 'S' && !showContracted && (
        <Card
          title={t('details.defaultOverflowRanges')}
          style={{ marginTop: 16 }}
        >
          <OverflowRangesConfig
            overflowRanges={overflowRanges}
            noInteraction
            noTitle
          />
        </Card>
      )}

      {contractDetails?.operatorDiffChargeFlag === 'S' && (
        <Card
          title={t('details.defaultPricingPlanOperator')}
          style={{ marginTop: 16 }}
        >
          <DifPerOperatorConfig
            difPerOperator={pricingPlanOperator}
            noInteraction
            noTitle
          />
        </Card>
      )}

      {contractView === 'active' && (
        <div style={{ marginTop: 16 }}>
          <Requests
            isInTheContractDetails
            defaultFilters={{
              showContracted,
              contractId: contractDetails?.id,
            }}
            defaultContractId={contractDetails?.id}
            defaultCustomerId={customerDetails?.id}
            defaultColumns={[
              'id',
              'name',
              'operatorDiffChargeFlag',
              'value',
              'activationPrice',
              'megabyteExcPrice',
              'automaticBlocking',
              'actions',
            ]}
          />
        </div>
      )}

      {showAddCredit && (
        <RoleChecker role={rolesConstants.ADD_CREDIT_TO_CONTRACT}>
          <AddCredit
            creditType="contract"
            idToAdd={contractDetails?.id}
            visible={showAddCredit}
            onClose={() => setShowAddCredit(false)}
          />
        </RoleChecker>
      )}
    </Skeleton>
  );
};

export default DetailedContract;
