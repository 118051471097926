import roles from '../utils/rolesConstants';

const management = t => ({
  name: 'Management',
  url: '/management',
  children: [
    {
      name: 'Home',
      url: '',
    },
    {
      name: t('menu.dashboards'),
      url: '/dashboards',
      role: roles.DASHBOARD,
    },
    {
      name: t('menu.inventory'),
      url: '/inventory',
      role: roles.MANAGEMENT,
    },
    {
      name: t('menu.actions-queue'),
      url: '/actions-queue',
      role: roles.ACTIONS_QUEUE,
    },
    {
      name: t('menu.operational'),
      url: '/operational',
      role: roles.VIEW_OPERATIONAL,
    },
    {
      name: t('menu.reports'),
      url: '/reports',
      role: roles.REQUEST_REPORT,
    },
    {
      name: t('menu.transfer'),
      url: '/transfer',
      role: roles.VIEW_TRANSFERS,
    },
    {
      name: t('menu.maps'),
      url: '/maps',
      role: roles.LOCATION,
    },
  ],
});

const financial = t => ({
  name: t('menu.financial'),
  url: '/financial',
  children: [
    {
      name: t('menu.contracts'),
      url: '/contracts',
      role: roles.VIEW_PLANS,
    },
    {
      name: t('menu.requests'),
      url: '/requests',
      role: roles.VIEW_ACCOUNTS,
    },
    {
      name: t('menu.invoices'),
      url: '/invoices',
      role: roles.VIEW_INVOICES,
    },
    {
      name: t('menu.erp-config'),
      url: '/erp-config',
      role: roles.VIEW_ERP_CONFIG,
    },
  ],
});

const administrative = t => ({
  name: t('menu.administrative'),
  url: '/administrative',
  children: [
    {
      name: t('menu.customers'),
      url: '/customers',
      role: roles.VIEW_CUSTOMERS,
    },
    {
      name: t('menu.accounts'),
      url: '/accounts',
      role: roles.VIEW_USERS,
    },
    {
      name: t('menu.roles'),
      url: '/roles',
      role: roles.VIEW_USERS,
    },
    {
      name: t('menu.banners'),
      url: '/banners',
      role: roles.MANAGE_BANNERS,
    },
    {
      name: t('menu.terms-use'),
      url: '/terms-use',
      role: roles.MANAGE_TERMS_OF_USE,
    },
  ],
});

const schedules = t => ({
  name: t('menu.schedules'),
  url: '/schedule',
  children: [
    {
      name: t('menu.company'),
      url: '/company',
      role: roles.ASTRO,
    },
    {
      name: t('menu.clients'),
      url: '/customers',
      role: roles.ASTRO,
    },
    {
      name: t('menu.schedule'),
      url: '/schedules',
      role: roles.ASTRO,
    },
  ],
});

const sms = t => ({
  name: t('menu.sms'),
  url: '/sms',
  children: [
    {
      name: t('menu.sms-request'),
      url: '/requests',
      role: roles.SMS_SEND,
    },
    {
      name: t('menu.sms-senders'),
      url: '/senders',
      role: roles.SMS_SENDERS,
    },
    {
      name: t('menu.sms-contracts'),
      url: '/contracts-sms',
      role: roles.SMS_CONTRACT,
    },
    {
      name: t('menu.sms-hiring'),
      url: '/hiring',
      role: roles.SMS_HIRING,
    },
    {
      name: t('menu.sms-configurations'),
      url: '/configuration',
      role: roles.MANAGE_SMS,
    },
  ],
});

const lorawan = t => ({
  name: t('menu.lorawan'),
  url: '/lorawan',
  children: [
    {
      name: t('menu.dashboards'),
      url: '/dashboards',
      role: roles.LORAWAN_VIEW_DASHBOARDS,
    },
    {
      name: t('menu.inventory'),
      url: '/inventory',
      role: roles.LORAWAN_VIEW_DEVICES,
    },
    {
      name: t('menu.actions-queue'),
      url: '/actions-queue',
      role: roles.LORAWAN_VIEW_ACTIONS_QUEUE,
    },
    {
      name: t('menu.contracts'),
      url: '/contracts',
      role: roles.LORAWAN_VIEW_CONTRACTS,
    },
    {
      name: t('menu.requests'),
      url: '/requests',
      role: roles.LORAWAN_VIEW_REQUESTS,
    },
    {
      name: t('menu.transfer'),
      url: '/transfer',
      role: roles.LORAWAN_VIEW_TRANSFERS,
    },
  ],
});

export default t => [
  management(t),
  financial(t),
  administrative(t),
  schedules(t),
  sms(t),
  lorawan(t),
];
