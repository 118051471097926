import React, { useState, useCallback, useMemo } from 'react';
import {
  Select,
  Input,
  InputNumber,
  message,
  Row,
  Col,
  Divider,
  Radio,
} from 'antd';
import { useTranslation } from 'react-i18next';
import ModalOfSteps from '@src/components/ModalOfSteps/ModalOfSteps';
import apiMiddleware from '@src/services/apiMiddleware';
import LabelModel from '@src/components/LabelModel/LabelModel';
import SelectCustomers from '@src/components/SelectCustomers/index';

const { Option } = Select;

const NewContractModal = ({ visible, onClose, mutateNewContract }) => {
  const { t } = useTranslation(['contracts', 'attributes', 'placeholders']);

  const [customerId, setCustomerId] = useState();
  const [planDesc, setPlanDesc] = useState();
  const [activationPrice, setActivationPrice] = useState(0);
  const [loyaltyType, setLoyaltyType] = useState(0);
  const [loyaltyTime, setLoyaltyTime] = useState();
  const [penalty, setPenalty] = useState(0);
  const [externalId, setExternalId] = useState();
  const [typeOfContract, setTypeOfContract] = useState();
  const [uplinkCount, setUplinkCount] = useState(0);
  const [downlinkCount, setDownlinkCount] = useState(0);
  const [excessUplinkPrice, setExcessUplinkPrice] = useState(0);
  const [excessDownlinkPrice, setExcessDownlinkPrice] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [monthlyFee, setMonthlyFee] = useState(0);

  const createContract = useCallback(
    payload =>
      apiMiddleware.post('/service-proxy/lorawanVirtualPlans', payload),
    [],
  );

  const cleanStateAndClose = useCallback(() => {
    setCustomerId(undefined);
    setPlanDesc(undefined);
    setLoyaltyType(0);
    setLoyaltyTime();
    setPenalty(0);
    setMonthlyFee(0);
    setExternalId(undefined);
    onClose();
  }, [onClose]);

  const handleSaveContract = useCallback(async () => {
    const payload = {
      customerId,
      monthlyFee,
      activationPrice,
      planDesc,
      loyaltyType,
      loyaltyTime,
      penalty,
      externalId,
      sharingType: typeOfContract,
      uplinkCount,
      downlinkCount,
      excessUplinkPrice,
      excessDownlinkPrice,
    };

    setConfirmLoading(true);
    try {
      await createContract(payload);
      mutateNewContract(payload);
      message.success(t('msgs.success'));
      cleanStateAndClose();
    } catch {
      message.error(t('msgs.error'));
      cleanStateAndClose();
    }
    setConfirmLoading(false);
  }, [
    customerId,
    monthlyFee,
    activationPrice,
    planDesc,
    loyaltyType,
    loyaltyTime,
    penalty,
    externalId,
    typeOfContract,
    uplinkCount,
    downlinkCount,
    excessUplinkPrice,
    excessDownlinkPrice,
    createContract,
    mutateNewContract,
    t,
    cleanStateAndClose,
  ]);

  const penaltyForCancellationOptions = useMemo(
    () => [
      {
        label: t('attributes:typesOfPenalty.noPenalty'),
        value: 0,
      },
      {
        label: t('attributes:typesOfPenalty.fixedPenalty'),
        value: 1,
      },
      {
        label: t('attributes:typesOfPenalty.penaltyOnContract'),
        value: 2,
      },
    ],
    [t],
  );

  const typeOptions = useMemo(
    () => [
      {
        label: t('attributes:type.sharedWithout'),
        value: 1,
      },
      {
        label: t('attributes:type.sharedTotal'),
        value: 2,
      },
    ],
    [t],
  );

  const newContractSteps = useMemo(
    () => [
      {
        title: t('attributes:client'),
        disableNextButton: !customerId && !typeOfContract,
        content: (
          <Row gutter={[16, 16]} justify="center">
            <Col lg={16} xl={16} xs={24}>
              <SelectCustomers
                showLabel
                value={customerId}
                onChange={setCustomerId}
              />
            </Col>
          </Row>
        ),
      },
      {
        title: t('newContract.typeOfContract'),
        disableNextButton: !typeOfContract,
        content: (
          <Row gutter={[16, 16]} justify="center">
            <Radio.Group
              optionType="button"
              options={typeOptions}
              value={typeOfContract}
              onChange={({ target: { value } }) => setTypeOfContract(value)}
            />
          </Row>
        ),
      },
      {
        title: t('newContract.config'),
        disableNextButton: !planDesc || planDesc === '',
        content: (
          <>
            <Row gutter={[16, 10]} style={{ marginBottom: 10 }} justify="start">
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:description')} isRequired />
                <Input
                  placeholder={t('placeholders:description')}
                  value={planDesc}
                  onChange={e => setPlanDesc(e.target.value)}
                  allowClear
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:externalId')} />
                <Input
                  placeholder={t('placeholders:externalId')}
                  value={externalId}
                  onChange={e => setExternalId(e.target.value)}
                  allowClear
                />
              </Col>
            </Row>
            <Divider
              style={{
                marginTop: 40,
                fontSize: '0.85rem',
                opacity: 0.7,
                textTransform: 'uppercase',
              }}
            >
              {t('details.billingModel')}
            </Divider>
            <Row gutter={[16, 10]} style={{ marginBottom: 10 }}>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:monthlyPayment')} />
                <InputNumber
                  defaultValue={monthlyFee}
                  formatter={value => `R$ ${value}`}
                  min={0}
                  parser={value => value.replace('R$ ', '')}
                  step={0.01}
                  style={{ width: '100%' }}
                  onChange={value => setMonthlyFee(value)}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:activationPrice')} />
                <InputNumber
                  defaultValue={activationPrice}
                  formatter={value => `R$ ${value}`}
                  min={0}
                  parser={value => value.replace('R$ ', '')}
                  step={0.01}
                  style={{ width: '100%' }}
                  onChange={value => setActivationPrice(value)}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:uplinkCount')} />
                <InputNumber
                  defaultValue={0}
                  min={0}
                  step={1}
                  style={{ width: '100%' }}
                  value={uplinkCount}
                  onChange={setUplinkCount}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:excessUplinkPrice')} />
                <InputNumber
                  defaultValue={0}
                  formatter={value => `R$ ${value}`}
                  min={0}
                  parser={value => value.replace('R$ ', '')}
                  step={0.01}
                  style={{ width: '100%' }}
                  value={excessUplinkPrice}
                  onChange={setExcessUplinkPrice}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:downlinkCount')} />
                <InputNumber
                  defaultValue={0}
                  min={0}
                  step={1}
                  style={{ width: '100%' }}
                  value={downlinkCount}
                  onChange={setDownlinkCount}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:excessDownlinkPrice')} />
                <InputNumber
                  defaultValue={0}
                  formatter={value => `R$ ${value}`}
                  min={0}
                  parser={value => value.replace('R$ ', '')}
                  step={0.01}
                  style={{ width: '100%' }}
                  value={excessDownlinkPrice}
                  onChange={setExcessDownlinkPrice}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:penaltyForCancellation')} />
                <Select
                  options={penaltyForCancellationOptions}
                  style={{ width: '100%' }}
                  placeholder={t('placeholders:penaltyForCancellation')}
                  allowClear
                  showSearch
                  value={loyaltyType}
                  onChange={setLoyaltyType}
                />
              </Col>
              {loyaltyType === 1 && (
                <Col lg={12} xl={12} xs={24}>
                  <LabelModel text={t('attributes:penaltyAmount')} />
                  <InputNumber
                    defaultValue={0}
                    formatter={value => `R$ ${value}`}
                    min={0}
                    parser={value => value.replace('R$ ', '')}
                    step={0.01}
                    style={{ width: '100%' }}
                    value={penalty}
                    onChange={setPenalty}
                  />
                </Col>
              )}
              {loyaltyType === 2 && (
                <Col lg={12} xl={12} xs={24}>
                  <LabelModel text={t('attributes:loyaltyTime')} isRequired />
                  <Select
                    style={{ width: '100%' }}
                    value={loyaltyTime}
                    onChange={setLoyaltyTime}
                    allowClear
                  >
                    {[12, 24, 36].map(item => (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )}
            </Row>
          </>
        ),
      },
    ],
    [
      t,
      customerId,
      typeOfContract,
      typeOptions,
      planDesc,
      externalId,
      monthlyFee,
      activationPrice,
      uplinkCount,
      excessUplinkPrice,
      downlinkCount,
      excessDownlinkPrice,
      penaltyForCancellationOptions,
      loyaltyType,
      penalty,
      loyaltyTime,
    ],
  );

  return (
    <ModalOfSteps
      title={t('newContract.title')}
      width="56%"
      handleConfirm={handleSaveContract}
      visible={visible}
      steps={newContractSteps}
      onClose={cleanStateAndClose}
      confirmLoading={confirmLoading}
      disableConfirmButton={
        !planDesc || planDesc === '' || (loyaltyType === 2 && !loyaltyTime)
      }
    />
  );
};

export default NewContractModal;
