import React, {
  useCallback,
  useState,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import {
  Input,
  Col,
  Tag,
  Button,
  Progress,
  Select,
  Tooltip,
  Tabs,
  Divider,
} from 'antd';
import { FileSearchOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { FiExternalLink } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { Label } from '@components/ui';
import Table from '@components/Table';
import { Filters, FiltersCard } from '@components/Filters';
import 'moment/locale/pt-br';
import useSwr from '@hooks/useSwr';
import RequestReport from '@components/RequestReport/RequestReport';
import Actions from '@components/Actions';
import useSearchParams from '@src/hooks/useSearchParams';
import rolesConstants from '@utils/rolesConstants';
import { useNavigate } from 'react-router-dom';
import SessionContext from '@src/store/SessionContext/SessionContext';
import RoleChecker from '@components/RoleChecker/RoleChecker';
import DevicesActionsModal from './InventoryActionsModal';
import moment from 'moment';

import * as S from './styles';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';

const { Search } = Input;
const { TabPane } = Tabs;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '50', '100'],
  total: 1,
};

const Inventory = ({ isInTheRequestDetails, defaultFilters }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['lorawan', 'inventory']);
  const { keycloak } = useContext(KeycloakContext);

  const {
    customer: { customersOptions },
  } = useContext(SessionContext);
  const customerType = keycloak?.idTokenParsed?.customerType;
  const isEndCustomer = customerType === 'CUSTOMER';
  const isSuperBroker = customerType === 'SUPERBROKER';

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [showFilters, setShowFilters] = useState(false);
  const [devEui, setDevEui] = useState();
  const [devEuiValue, setDevEuiValue] = useState();
  const [customerId, setCustomerId] = useState();
  const [actionToExecute, setActionToExecute] = useState();
  const [modalDeviceActionsVisible, setModalDeviceActionsVisible] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [showContracted, setShowContracted] = useState();
  const [stock, setStock] = useState();

  const { data } = useSwr(
    '/service-proxy/lorawanDevices/MOT',
    {
      devEui,
      customerId,
      stock,
      showContracted: showContracted ?? 'false',
      page: pagination?.current - 1,
      linesPerPage: pagination.pageSize,
      ...defaultFilters,
    },
    { revalidateOnFocus: false },
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'showContracted',
        setState: setShowContracted,
        inTheFilters: false,
      },
      {
        name: 'stock',
        setState: setStock,
        inTheFilters: false,
      },
      {
        name: 'devEui',
        setState: setDevEui,
        inTheFilters: true,
      },
      {
        name: 'customerId',
        setState: setCustomerId,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    setDevEuiValue(devEui);
  }, [devEui]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [devEui, customerId, showContracted, stock]);

  useEffect(() => {
    if (isEndCustomer) {
      setStock(null);
      setShowContracted('true');
      handleClearParams(['showContracted']);
    }
  }, [customerType, isEndCustomer, handleClearParams]);

  const stockOptions = useMemo(
    () => [
      { label: t('inventory:stock-values.all'), value: null },
      { label: t('inventory:stock-values.inStock'), value: 'true' },
      { label: t('inventory:stock-values.allocated'), value: 'false' },
    ],
    [t],
  );

  const renderActivatedTag = useCallback(
    isActivated => (
      <Tag color={isActivated ? 'green' : 'red'}>
        {t(isActivated ? 'activated' : 'deactivated')}
      </Tag>
    ),
    [t],
  );

  const renderProgressMsgs = useCallback(
    (consumption, dailyConsumption, total) => {
      const dailyAndConsumed =
        Number(consumption ?? 0) + Number(dailyConsumption ?? 0);
      const percentage = (
        (dailyAndConsumed / Number(total ?? 0)) *
        100
      )?.toFixed();
      const consumptionToShow = `${dailyAndConsumed}/${total}`;
      const hasPercentage = percentage && percentage !== '0';

      return (
        <S.ConsumeProgressPopover
          percentage={percentage}
          className="msgs-consumption-progress"
          content={
            <S.ConsumeProgressPopoverContent>
              <S.ConsumeProgressPopoverContentItem>
                {`${t('dailyConsumption')}:`}
                <Tag>{dailyConsumption ?? 0}</Tag>
              </S.ConsumeProgressPopoverContentItem>
              <S.ConsumeProgressPopoverContentItem>
                {`${t('validatedConsumption')}:`}
                <Tag>{consumption}</Tag>
              </S.ConsumeProgressPopoverContentItem>
              {!(isSuperBroker && showContracted === 'true') && (
                <S.ConsumeProgressPopoverContentItem>
                  {`${t('franchise')}:`}
                  <Tag>{total}</Tag>
                </S.ConsumeProgressPopoverContentItem>
              )}
            </S.ConsumeProgressPopoverContent>
          }
        >
          {showContracted === 'true' && isSuperBroker ? (
            <p>{dailyAndConsumed}</p>
          ) : (
            <S.PercentageBar>
              {hasPercentage && (
                <Progress
                  size="small"
                  percent={percentage}
                  style={{ fontSize: '0.7rem' }}
                />
              )}
              <p>
                {hasPercentage ? `(${consumptionToShow})` : consumptionToShow}
              </p>
            </S.PercentageBar>
          )}
        </S.ConsumeProgressPopover>
      );
    },
    [isSuperBroker, showContracted, t],
  );

  const formatActivationDate = useCallback(
    date => date && moment(date)?.format('DD/MM/YY'),
    [],
  );

  const columns = useMemo(() => {
    const defaultColumns = [
      { title: t('description'), dataIndex: 'description' },
      {
        title: t('devEui'),
        dataIndex: 'devEui',
        align: 'center',
      },
      {
        title: t('tags'),
        dataIndex: 'tags',
        render: tags => tags?.map(tag => <Tag key={tag}>{tag}</Tag>),
      },
      {
        title: t('appEui'),
        dataIndex: 'appEui',
        align: 'center',
      },
      {
        title: t('activation'),
        dataIndex: 'activationType',
        align: 'center',
        render: value => <Tag>{value}</Tag>,
      },
      {
        title: t('encryption'),
        dataIndex: 'encryptionType',
        align: 'center',
        render: value => <Tag>{value}</Tag>,
      },
      {
        title: t('downlinksConsumption'),
        dataIndex: 'downlinksConsumed',
        align: 'center',
        render: (downlinksConsumed, record) =>
          renderProgressMsgs(
            downlinksConsumed,
            record?.dailyDownlinkConsumption,
            record?.virtualAccount?.downlinkCount,
          ),
      },
      {
        title: t('uplinksConsumption'),
        dataIndex: 'uplinksConsumed',
        align: 'center',
        render: (uplinksConsumed, record) =>
          renderProgressMsgs(
            uplinksConsumed,
            record?.dailyUplinkConsumption,
            record?.virtualAccount?.uplinkCount,
          ),
      },
      {
        title: t('blockUplink'),
        dataIndex: 'blockUplink',
        align: 'center',
        render: renderActivatedTag,
      },
      {
        title: t('blockDownlink'),
        dataIndex: 'blockDownlink',
        align: 'center',
        render: renderActivatedTag,
      },
      {
        title: t('activationDate'),
        dataIndex: 'activationDate',
        align: 'center',
        render: formatActivationDate,
      },
      {
        title: t('details'),
        dataIndex: 'actions',
        align: 'center',
        render: (_, record) => (
          <Button
            type="link"
            onClick={() => navigate(`/lorawan/inventory/${record?.devEui}`)}
          >
            <FileSearchOutlined />
          </Button>
        ),
      },
    ];

    if (showContracted === 'true' && isSuperBroker) {
      defaultColumns.unshift({
        title: t('assetId'),
        dataIndex: 'contract',
        render: value => value?.assetNumber,
      });
    } else {
      defaultColumns.unshift({
        title: t('request'),
        dataIndex: 'virtualAccount',
        render: value => (
          <Button
            type="link"
            onClick={() => navigate(`/lorawan/requests/${value?.id}`)}
          >
            {value?.name}
          </Button>
        ),
      });
    }
    if (!showContracted || showContracted === 'false') {
      defaultColumns.unshift({
        title: t('customer'),
        dataIndex: 'customer',
        render: value => value?.name,
      });
    }

    return defaultColumns;
  }, [
    formatActivationDate,
    isSuperBroker,
    navigate,
    renderActivatedTag,
    renderProgressMsgs,
    showContracted,
    t,
  ]);

  const requestReportItems = useMemo(() => {
    const items = [
      {
        role: rolesConstants.REQUEST_REPORT,
        filters: {
          stock,
          devEui,
          customerId,
          showContracted: showContracted ?? 'false',
        },
        isParams: true,
        path: `/service-proxy/lorawanReport/deviceMOT`,
        type: 'LORAWAN_DEVICE_MOT',
        method: 'get',
      },
    ];

    return items;
  }, [customerId, devEui, showContracted, stock]);

  const handleActionClick = useCallback(actionRole => {
    setActionToExecute(actionRole);
    setModalDeviceActionsVisible(true);
  }, []);

  const extraActions = useMemo(
    () => (
      <>
        {!isInTheRequestDetails && isSuperBroker && (
          <RoleChecker role={rolesConstants.LORAWAN_ACTIVATION}>
            <Tooltip placement="top" title={t('activation')}>
              <Button
                icon={<PlusCircleOutlined />}
                size="large"
                type="link"
                onClick={() => {
                  setActionToExecute(rolesConstants.LORAWAN_ACTIVATION);
                  setModalDeviceActionsVisible(true);
                }}
              />
            </Tooltip>
          </RoleChecker>
        )}
        <Actions.Menu onActionClick={handleActionClick} />

        <RoleChecker role={rolesConstants.REQUEST_REPORT}>
          <RequestReport items={requestReportItems} />
        </RoleChecker>
      </>
    ),
    [
      isSuperBroker,
      t,
      handleActionClick,
      requestReportItems,
      isInTheRequestDetails,
    ],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const isUpload = useMemo(
    () =>
      !(selectedRows && selectedRows.length > 0) ||
      actionToExecute === rolesConstants.LORAWAN_ACTIVATION,
    [actionToExecute, selectedRows],
  );

  const handleTabsChange = useCallback(
    key => {
      if (!isEndCustomer) {
        if (key === 'true') {
          handleSetSearchParams({ showContracted: key });
        } else {
          setShowContracted('false');
          handleClearParams(['stock', 'showContracted']);
        }
      }
    },
    [handleSetSearchParams, handleClearParams, isEndCustomer],
  );

  const handleTabFilterChange = useCallback(
    value => {
      switch (value) {
        case 'null':
          setStock('null');
          handleClearParams(['stock']);
          break;
        case 'true':
          handleSetSearchParams({ stock: value }, ['customer']);
          break;
        case 'false':
          handleSetSearchParams({ stock: value });
          break;
        default:
          break;
      }
    },
    [handleSetSearchParams, handleClearParams],
  );

  return (
    <div>
      <DevicesActionsModal
        visible={modalDeviceActionsVisible}
        onClose={() => setModalDeviceActionsVisible(false)}
        actionRole={actionToExecute}
        selectedDevices={selectedRows}
        isUpload={isUpload}
      />
      {!isEndCustomer && !isInTheRequestDetails && (
        <Tabs
          type="card"
          defaultActiveKey="receive"
          activeKey={showContracted}
          onChange={handleTabsChange}
          style={{ background: '#ffffff' }}
        >
          <Tabs.TabPane
            key="false"
            tab={t('inventory:visions-steps.receive')}
          />
          <Tabs.TabPane key="true" tab={t('inventory:visions-steps.pay')} />
        </Tabs>
      )}

      <Table
        columns={columns}
        data={data?.content}
        loading={!data?.content}
        pagination={{
          ...pagination,
          total: data?.totalElements,
        }}
        tableKey="lorawanDevices"
        title={
          <>
            {t('devices')}
            {data && (
              <span
                style={{ fontSize: '0.9rem', opacity: 0.6, marginRight: 4 }}
              >
                {` (${data?.content?.length} ${t('inventory:of', {
                  total: data?.totalElements,
                })})`}
              </span>
            )}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        selectable
        rowKey="devEui"
        setSelectedRowKeys={(keys, rows) => {
          setSelectedRows(rows);
        }}
        onChange={handleTableChange}
        extraActions={extraActions}
        tabsFilters={
          !isEndCustomer &&
          showContracted === 'true' && (
            <Tabs
              tabBarStyle={{
                margin: 0,
                padding: '0 20px',
                background: showFilters ? '#ffffff' : '#f9f9f9',
                borderBottom: '2px solid #808fe671',
                active: 'red',
              }}
              activeKey={stock}
              onChange={handleTabFilterChange}
            >
              {stockOptions?.map(step => (
                <TabPane key={step.value} tab={step.label} />
              ))}
            </Tabs>
          )
        }
        extraFilters={
          showFilters && (
            <FiltersCard>
              {showContracted !== 'true' && (
                <Col lg={12} sm={12} xl={8} xs={24}>
                  <div style={{ marginBottom: 5 }}>
                    <Label color={'#575962'}>{t('customer')}</Label>
                  </div>
                  <Select
                    loading={!customersOptions}
                    disabled={!customersOptions}
                    optionFilterProp={'label'}
                    options={customersOptions}
                    placeholder={t('customer')}
                    style={{ width: '100%' }}
                    value={customerId ? Number(customerId) : undefined}
                    allowClear
                    showSearch
                    onChange={value =>
                      handleSetSearchParams({ customerId: value })
                    }
                  />
                </Col>
              )}
              <Col lg={12} sm={12} xl={8} xs={24}>
                <div style={{ marginBottom: 5 }}>
                  <Label color={'#575962'}>{t('devEui')}</Label>
                </div>
                <Search
                  placeholder={t('devEui')}
                  onSearch={value => handleSetSearchParams({ devEui: value })}
                  onChange={({ target: { value } }) => setDevEuiValue(value)}
                  value={devEuiValue}
                  allowClear
                />
              </Col>
            </FiltersCard>
          )
        }
        mainExtraAction={
          isInTheRequestDetails && (
            <>
              <Divider type="vertical" />
              <Tooltip title={t('requests:viewOnInventoryPage')}>
                <Button
                  icon={
                    <FiExternalLink
                      style={{
                        fontSize: '1.4rem',
                        marginTop: 4,
                      }}
                    />
                  }
                  size="large"
                  type="link"
                  onClick={() => {
                    navigate(
                      `/lorawan/inventory?showContracted=${defaultFilters?.showContracted}&virtualAccountId=${defaultFilters?.accountId}`,
                    );
                  }}
                />
              </Tooltip>
            </>
          )
        }
      />
    </div>
  );
};

export default Inventory;
