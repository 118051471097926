import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext,
} from 'react';
import { Input, ConfigProvider, Col, Select, Button, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Label } from '@components/ui';
import Table from '@components/Table';

import {
  dateFormat,
  formatBRNumber,
  getDateInISOFormat,
} from '@utils/formatters';
import { Filters, FiltersCard } from '@components/Filters';
import 'moment/locale/pt-br';
import { getAntLocale } from '@utils/locales';
import useSwr from '@hooks/useSwr';
import moment from 'moment';
import useSearchParams from '@src/hooks/useSearchParams';
import QuickRangePicker from '@src/components/DatePicker/QuickRangePicker/QuickRangePicker';
import StatusMenu from '@src/components/StatusMenu/StatusMenu';
import SessionContext from '@src/store/SessionContext/SessionContext';
import { LorawanActionTypes } from '@src/utils/deviceActions';

const { Search } = Input;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30', '50', '100'],
  total: 1,
};

const ActionsQueue = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('lorawan');

  const { mainColor } = useContext(SessionContext);

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [actionType, setActionType] = useState();
  const [devEui, setDevEui] = useState();
  const [devEuiValue, setDevEuiValue] = useState();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [status, setStatus] = useState();
  const [showFilters, setShowFilters] = useState(false);

  const { data } = useSwr(
    '/service-proxy/lorawan/actions?product=LORAWAN_MOT',
    {
      actionType,
      devEui,
      status,
      startDate: startDate !== '' ? startDate : undefined,
      endDate: endDate !== '' ? endDate : undefined,
      page: pagination?.current - 1,
      linesPerPage: pagination.pageSize,
    },
    {
      refreshInterval: 10 * 1000,
      revalidateOnFocus: false,
    },
  );

  const { data: dataCountByType } = useSwr(
    '/service-proxy/lorawan/actions/count-by-type?product=LORAWAN_MOT',
    undefined,
    { refreshInterval: 10 * 1000, revalidateOnFocus: false },
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'actionType',
        setState: setActionType,
        inTheFilters: false,
      },
      {
        name: 'devEui',
        setState: setDevEui,
        inTheFilters: true,
      },
      {
        name: 'status',
        setState: setStatus,
        inTheFilters: true,
      },
      {
        name: 'startDate',
        setState: setStartDate,
        inTheFilters: true,
      },
      {
        name: 'endDate',
        setState: setEndDate,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    setDevEuiValue(devEui);
  }, [devEui]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [actionType, devEui, status, startDate, endDate]);

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: t('action'),
        dataIndex: 'actionType',
        render: action => t(`actions.byKey.${action}`),
      },
      {
        title: t('devEui'),
        dataIndex: 'devEui',
        align: 'center',
      },
      {
        title: t('createdBy'),
        dataIndex: 'createdBy',
        align: 'center',
      },
      {
        title: t('createdAt'),
        dataIndex: 'createdAt',
        align: 'center',
        render: value => value && dateFormat(value),
      },
      {
        title: t('processingDate'),
        dataIndex: 'processingDate',
        align: 'center',
        render: value => value && dateFormat(value),
      },
      {
        title: t('status'),
        dataIndex: 'status',
        render: statusCode => t(`actions.status.${statusCode}`),
        align: 'center',
      },
    ];

    if (!actionType || actionType === String(LorawanActionTypes.ACTIVATION)) {
      defaultColumns.splice(1, 0, {
        title: t('contract'),
        dataIndex: 'idContract',
      });
    }

    return defaultColumns;
  }, [actionType, t]);

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  const handleDateChange = useCallback(
    values => {
      const start = getDateInISOFormat(values.initialDate);
      const end = getDateInISOFormat(values.endDate);
      handleSetSearchParams({ startDate: start, endDate: end });
    },
    [handleSetSearchParams],
  );

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams(['action'], 'except');
      }
    },
    [showFilters, handleClearParams],
  );

  const statusOptions = useMemo(
    () =>
      ['SUCCESS', 'PROCESSING_QUEUE', 'PROCESSING', 'ERROR'].map(
        statusItem => ({
          label: t(`actions.status.${statusItem}`),
          value: statusItem,
        }),
      ),
    [t],
  );

  const statusMenuActionsData = useMemo(() => {
    const typeData = [2, 3, 10, 11, 12, 13, 14, 15, 50]
      ?.map(item => {
        const getActionCount = dataCountByType?.find(
          el => Number(el.item) === item,
        )?.value;

        if (!getActionCount) {
          return;
        }

        return {
          label: t(`actions.byKey.${item}`),
          item: item.toString(),
          value: getActionCount ?? 0,
          color: mainColor ?? '#5867dd',
        };
      })
      ?.sort((a, b) => a.value - b.value)
      ?.filter(el => !!el);

    return typeData?.reverse();
  }, [dataCountByType, mainColor, t]);

  const actionsTotal = useMemo(
    () =>
      statusMenuActionsData?.reduce(
        (accumulator, item) => accumulator + (item?.value ?? 0),
        0,
      ),
    [statusMenuActionsData],
  );

  return (
    <div>
      {dataCountByType?.length > 0 && (
        <div
          style={{
            padding: 8,
            background: '#ffffff',
            borderRadius: 6,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              type={!actionType && 'primary'}
              style={{
                margin: '6px 4px',
                display: 'flex',
                justifyContent: 'space-between',
                borderColor: '#f1f1f1',
                fontSize: '0.9rem',
              }}
              onClick={() => handleClearParams(['actionType', 'status'])}
            >
              {t('device-actions:all-actions')}
            </Button>
            {actionsTotal ? (
              <Tag
                style={{
                  fontSize: '1rem',
                  padding: '4px 8px',
                  marginLeft: 2,
                }}
                color="success"
              >
                {formatBRNumber(actionsTotal)}
              </Tag>
            ) : undefined}
          </div>
          <StatusMenu
            style={{ marginBottom: 16 }}
            itemActive={actionType}
            data={statusMenuActionsData}
            handleSelectedCard={item =>
              handleSetSearchParams({ actionType: item }, ['status'])
            }
          />
        </div>
      )}
      <Table
        columns={columns}
        data={data?.content}
        loading={!data?.content}
        pagination={{
          ...pagination,
          total: data?.totalElements,
        }}
        tableKey={'actions-queue'}
        title={
          <>
            {t('actionsQueue')}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
            />
          </>
        }
        onChange={handleTableChange}
        extraFilters={
          showFilters && (
            <FiltersCard>
              <Col lg={12} sm={12} xl={8} xs={24}>
                <div style={{ marginBottom: 5 }}>
                  <Label color={'#575962'}>{t('devEui')}</Label>
                </div>
                <Search
                  placeholder={t('devEui')}
                  onSearch={value => handleSetSearchParams({ devEui: value })}
                  onChange={({ target: { value } }) => setDevEuiValue(value)}
                  value={devEuiValue}
                  allowClear
                />
              </Col>

              <Col lg={12} sm={12} xl={8} xs={24}>
                <div style={{ marginBottom: 5 }}>
                  <Label color={'#575962'}>{t('status')}</Label>
                </div>
                <Select
                  loading={!statusOptions}
                  disabled={!statusOptions}
                  optionFilterProp={'label'}
                  options={statusOptions}
                  placeholder={t('status')}
                  style={{ width: '100%' }}
                  value={status}
                  allowClear
                  showSearch
                  onChange={value => handleSetSearchParams({ status: value })}
                />
              </Col>

              <Col lg={12} sm={12} xl={8} xs={24}>
                <div style={{ marginBottom: 5 }}>
                  <Label color={'#575962'}>{t('creationDate')}</Label>
                </div>
                <ConfigProvider locale={getAntLocale(language)}>
                  <QuickRangePicker
                    onChange={handleDateChange}
                    defaultValue={
                      startDate
                        ? [
                            moment(startDate, 'YYYYMMDD'),
                            moment(endDate, 'YYYYMMDD'),
                          ]
                        : undefined
                    }
                    value={
                      startDate
                        ? [
                            moment(startDate, 'YYYYMMDD'),
                            moment(endDate, 'YYYYMMDD'),
                          ]
                        : undefined
                    }
                    style={{ width: '100%' }}
                  />
                </ConfigProvider>
              </Col>
            </FiltersCard>
          )
        }
      />
    </div>
  );
};

export default ActionsQueue;
